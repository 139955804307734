<template>
  <div
    class="container"
    v-title
    id="breakdownReasonChart"
    :data-title="$t('i18n.breakdownReasonChart')"
  >
    <div id="outer-title">{{ $t("i18n.breakdownReasonChart") }}</div>
    <jl-chart v-if="!loading" :columns="option" name="option"></jl-chart>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlChart from "../../components/chart";
export default {
  name: "FaultReasonChart",
  components: {
    jlChart,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      loading: true,
      option: {
        title: t("i18n.breakdownReasonChart"),
        legend: [],
        type: "bar",
        xData: [],
        series: [],
      },
    });

    const init = async () => {
      let { data } = await proxy.$api.maintenance.getFaultReasonChart();
      data.map((item) => {
        let number = [],
          label = [];
        item.data.map((one) => {
          number.push(one.count);
          label.push(one.value);
        });
        state.option.legend.push(t("i18n." + item.name));
        state.option.series.push({
          markPoint: {
            data: [
              { type: "max", name: t("i18n.maximum") },
              { type: "min", name: t("i18n.minimum") },
            ],
          },
          markLine: {
            data: [{ type: "average", name: t("i18n.average") }],
          },
          name: t("i18n." + item.name),
          data: number,
        });
        state.option.xData = label;
      });
      state.loading = false;
    };

    init();

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss"></style>
